import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Layout from 'components/utils/layout';
import GridView from 'components/utils/gridView';
import Button from 'components/atoms/button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 5rem;
  width: 100%;
  height: 45vh;
  min-height: 40rem;

  ${({ theme: { mq } }) => mq.bigPhone} {
    margin: 5rem 0;
  }
  ${({ theme: { mq } }) => mq.phone} {
    margin: 15rem 0;
  }
`;

const Info = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.m};
  padding: 1.5rem 0;
`;

const Success = () => {
  const [timer, setTimer] = useState(8);

  useEffect(() => {
    setTimeout(function () {
      window.location.href = 'http://koncepcjanasukces.pl';
    }, 9000);
    let timerID;
    timerID = setInterval(countDown, 1000);

    function countDown() {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }
    return () => {
      clearInterval(timerID);
    };
  });

  return (
    <Layout>
      <GridView>
        <Wrapper>
          <Info>
            Wiadomość wysłana pomyślnie! <br /> Postaram się odpowiedzieć na
            Twoje zapytanie tak szybko jak będzie to możliwe!
          </Info>
          <p>Powrót do strony głównej nastąpi za: {timer}</p>
          <Button to='/'>Do strony głównej</Button>
        </Wrapper>
      </GridView>
    </Layout>
  );
};

export default Success;
